// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aviso-tsx": () => import("./../../../src/pages/aviso.tsx" /* webpackChunkName: "component---src-pages-aviso-tsx" */),
  "component---src-pages-como-funciona-tsx": () => import("./../../../src/pages/cómo-funciona.tsx" /* webpackChunkName: "component---src-pages-como-funciona-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preguntas-frecuentes-tsx": () => import("./../../../src/pages/preguntas-frecuentes.tsx" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

